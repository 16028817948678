// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._32YdE9Tzbq5XK8rz_2thXD {\n  width: 100%;\n  height: 100%;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  transition: all .5s ease-out;\n}\n", "",{"version":3,"sources":["Slide.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,4BAA4B;EAC5B,2BAA2B;EAC3B,4BAA4B;AAC9B","file":"Slide.css","sourcesContent":[".Slide {\n  width: 100%;\n  height: 100%;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  transition: all .5s ease-out;\n}\n"]}]);
// Exports
exports.locals = {
	"Slide": "_32YdE9Tzbq5XK8rz_2thXD"
};
module.exports = exports;
