// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/icons/drag-cursor.png");
exports = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._2NxK7wz0DWDyfb6CUUzA3n {\n  height: 100vh;\n  width: 100%;\n  position: relative;\n  overflow: hidden;\n  background-color: var(--gold);\n}\n\n  ._2NxK7wz0DWDyfb6CUUzA3n ._3nfgGPi43Eo_GrbWoVRpVt {\n    width: 100%;\n    height: 100%;\n    position: relative;\n    top: 10%;\n    \n    overflow-y: hidden;\n    overflow-x: scroll; \n    cursor: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 25 15, -webkit-grab; \n    cursor: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 25 15, grab;\n  }\n\n  ._2NxK7wz0DWDyfb6CUUzA3n ._3nfgGPi43Eo_GrbWoVRpVt::-webkit-scrollbar {\n      display: none;\n    }\n\n  ._2NxK7wz0DWDyfb6CUUzA3n ._3nfgGPi43Eo_GrbWoVRpVt ._2_BPzBzWIK-FP84xhAxJUe {\n      display: inline-flex;\n    }\n", "",{"version":3,"sources":["Projects.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,6BAA6B;AAoB/B;;EAlBE;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,QAAQ;;IAER,kBAAkB;IAClB,kBAAkB;IAClB,mEAA6D;IAA7D,2DAA6D;EAS/D;;EAPE;MACE,aAAa;IACf;;EAEA;MACE,oBAAoB;IACtB","file":"Projects.css","sourcesContent":[".Projects {\n  height: 100vh;\n  width: 100%;\n  position: relative;\n  overflow: hidden;\n  background-color: var(--gold);\n\n  .wrapper {\n    width: 100%;\n    height: 100%;\n    position: relative;\n    top: 10%;\n    \n    overflow-y: hidden;\n    overflow-x: scroll; \n    cursor: url('../../assets/icons/drag-cursor.png') 25 15, grab;\n\n    &::-webkit-scrollbar {\n      display: none;\n    }\n\n    .container {\n      display: inline-flex;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"Projects": "_2NxK7wz0DWDyfb6CUUzA3n",
	"wrapper": "_3nfgGPi43Eo_GrbWoVRpVt",
	"container": "_2_BPzBzWIK-FP84xhAxJUe"
};
module.exports = exports;
