// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._1exmzFowCmMPX0aoRC9HgY {\n  height: 100vh;\n  background-color: rgb(226, 233, 226);\n  transition: opacity .5s ease-in;\n}\n\n  ._1exmzFowCmMPX0aoRC9HgY h1 {\n    position: relative;\n    top: 8%;\n    right: 10%;\n    margin: 0;\n  }\n\n._2yongRG8YL5qfQSzTQ4Ky- {\n  width: 40%;\n  min-width: 20rem;\n}\n\n._1Wap3heQR1dqpThnTjZ27D {\n  width: 40%;\n  min-width: 20rem;\n  transform: translate(-20%, 10%);\n}\n", "",{"version":3,"sources":["Home.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,oCAAoC;EACpC,+BAA+B;AAQjC;;EANE;IACE,kBAAkB;IAClB,OAAO;IACP,UAAU;IACV,SAAS;EACX;;AAGF;EACE,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,+BAA+B;AACjC","file":"Home.css","sourcesContent":[".Home {\n  height: 100vh;\n  background-color: rgb(226, 233, 226);\n  transition: opacity .5s ease-in;\n\n  h1 {\n    position: relative;\n    top: 8%;\n    right: 10%;\n    margin: 0;\n  }\n}\n\n.largeFlowers {\n  width: 40%;\n  min-width: 20rem;\n}\n\n.smallFlowers {\n  width: 40%;\n  min-width: 20rem;\n  transform: translate(-20%, 10%);\n}\n"]}]);
// Exports
exports.locals = {
	"Home": "_1exmzFowCmMPX0aoRC9HgY",
	"largeFlowers": "_2yongRG8YL5qfQSzTQ4Ky-",
	"smallFlowers": "_1Wap3heQR1dqpThnTjZ27D"
};
module.exports = exports;
