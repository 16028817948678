// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._3mdW5ExRqscPG_cz8ckHhp {\n  cursor: pointer;\n  height: 4rem;\n}\n\n  ._3mdW5ExRqscPG_cz8ckHhp img {\n    width: 3.5rem;\n  }\n\n  ._3mdW5ExRqscPG_cz8ckHhp img._1jL7V4ojkNymO660N9VYfr, ._3mdW5ExRqscPG_cz8ckHhp p {\n    display: none;\n  }\n\n  ._3mdW5ExRqscPG_cz8ckHhp:hover img {\n      display: none;\n    }\n\n  ._3mdW5ExRqscPG_cz8ckHhp:hover p {\n      display: block;\n      height: 100%;\n    }\n\n._3sK9w0GNayf3aZU83nG0JX img._1jL7V4ojkNymO660N9VYfr {\n    display: block;\n  }\n\n._3sK9w0GNayf3aZU83nG0JX img._1vA95olcv0UZrbTmaw6raS, ._3sK9w0GNayf3aZU83nG0JX p {\n    display: none;\n  }\n\n._3sK9w0GNayf3aZU83nG0JX:hover img._1jL7V4ojkNymO660N9VYfr {\n      display: none;\n    }\n\n._3sK9w0GNayf3aZU83nG0JX:hover p {\n      display: block;\n      height: 100%;\n      color: var(--salmon);\n    }\n", "",{"version":3,"sources":["NavLink.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;AAoBd;;EAlBE;IACE,aAAa;EACf;;EAEA;IACE,aAAa;EACf;;EAGE;MACE,aAAa;IACf;;EAEA;MACE,cAAc;MACd,YAAY;IACd;;AAKF;IACE,cAAc;EAChB;;AAEA;IACE,aAAa;EACf;;AAGE;MACE,aAAa;IACf;;AAEA;MACE,cAAc;MACd,YAAY;MACZ,oBAAoB;IACtB","file":"NavLink.css","sourcesContent":[".NavLink {\n  cursor: pointer;\n  height: 4rem;\n\n  img {\n    width: 3.5rem;\n  }\n\n  img.solid, p {\n    display: none;\n  }\n\n  &:hover {\n    img {\n      display: none;\n    }\n\n    p {\n      display: block;\n      height: 100%;\n    }\n  }\n}\n\n.active {\n  img.solid {\n    display: block;\n  }\n\n  img.outline, p {\n    display: none;\n  }\n\n  &:hover {\n    img.solid {\n      display: none;\n    }\n\n    p {\n      display: block;\n      height: 100%;\n      color: var(--salmon);\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"NavLink": "_3mdW5ExRqscPG_cz8ckHhp",
	"solid": "_1jL7V4ojkNymO660N9VYfr",
	"active": "_3sK9w0GNayf3aZU83nG0JX",
	"outline": "_1vA95olcv0UZrbTmaw6raS"
};
module.exports = exports;
