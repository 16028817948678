// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._218eyviMpULvq6LwAuLpLC {\n  margin-left: 6rem;\n  height: 100vh;\n}\n  \n  ._218eyviMpULvq6LwAuLpLC section._23UCbwXznNQ0H1kia7r1-w {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  ._218eyviMpULvq6LwAuLpLC dl._13qz49bgeYTTi7lt72LVRC {\n    width: 40%;\n    height: 70vh;\n    display: flex;\n    flex-flow: column wrap;\n  }\n  \n  ._218eyviMpULvq6LwAuLpLC img {\n   position: relative;\n   left: 47%;\n   margin-bottom: 5%;\n  }\n  \n  @media only screen and (max-width: 768px) {._218eyviMpULvq6LwAuLpLC {\n    margin: 0\n}\n\n    ._218eyviMpULvq6LwAuLpLC section {\n      flex-direction: column;\n      align-items: center;\n    }\n\n    ._218eyviMpULvq6LwAuLpLC dl._13qz49bgeYTTi7lt72LVRC {\n      width: 60%;\n      height: 80%;\n    }\n  }\n", "",{"version":3,"sources":["Tech.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;AAkCf;;EAhCE;IACE,WAAW;IACX,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,UAAU;IACV,YAAY;IACZ,aAAa;IACb,sBAAsB;EACxB;;EAEA;GACC,kBAAkB;GAClB,SAAS;GACT,iBAAiB;EAClB;;EAEA,2CAvBF;IAwBI;AAYJ;;IAVI;MACE,sBAAsB;MACtB,mBAAmB;IACrB;;IAEA;MACE,UAAU;MACV,WAAW;IACb;EACF","file":"Tech.css","sourcesContent":[".Tech {\n  margin-left: 6rem;\n  height: 100vh;\n  \n  section.techList {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n  }\n\n  dl.definitionList {\n    width: 40%;\n    height: 70vh;\n    display: flex;\n    flex-flow: column wrap;\n  }\n\n  img {\n   position: relative;\n   left: 47%;\n   margin-bottom: 5%;\n  }\n  \n  @media only screen and (max-width: 768px) {\n    margin: 0;\n\n    section {\n      flex-direction: column;\n      align-items: center;\n    }\n\n    dl.definitionList {\n      width: 60%;\n      height: 80%;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"Tech": "_218eyviMpULvq6LwAuLpLC",
	"techList": "_23UCbwXznNQ0H1kia7r1-w",
	"definitionList": "_13qz49bgeYTTi7lt72LVRC"
};
module.exports = exports;
