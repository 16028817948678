// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._12OVj2Ux5aXu76wU3pxXvA {\n  width: 2.5rem;\n  height: 2.5rem;\n  position: absolute;\n  top: 44%;\n  opacity: 0.4;\n  cursor: pointer;\n  transition: all ease-in 0.2s;\n}\n\n  ._12OVj2Ux5aXu76wU3pxXvA:active {\n    opacity: 0.7\n  }\n\n  @media only screen and (max-width: 768px) {._12OVj2Ux5aXu76wU3pxXvA {\n    width: 1.5rem;\n    height: 1.5rem\n}\n  }\n", "",{"version":3,"sources":["CarouselArrow.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,kBAAkB;EAClB,QAAQ;EACR,YAAY;EACZ,eAAe;EACf,4BAA4B;AAU9B;;EARE;IACE;EACF;;EAEA,2CAbF;IAcI,aAAa;IACb;AAEJ;EADE","file":"CarouselArrow.css","sourcesContent":[".CarouselArrow {\n  width: 2.5rem;\n  height: 2.5rem;\n  position: absolute;\n  top: 44%;\n  opacity: 0.4;\n  cursor: pointer;\n  transition: all ease-in 0.2s;\n\n  &:active {\n    opacity: 0.7\n  }\n\n  @media only screen and (max-width: 768px) {\n    width: 1.5rem;\n    height: 1.5rem;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"CarouselArrow": "_12OVj2Ux5aXu76wU3pxXvA"
};
module.exports = exports;
