// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".lSNxAk0zL6pJkXmTGVSQp {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n  align-items: center;\n  \n  position: fixed;\n  height: 100vh;\n  width: 6rem;\n  z-index: 10;\n  \n  background-color: var(--charcoal);\n  transition: opacity .5s ease-in;\n}\n\n  @media only screen and (max-width: 768px) {.lSNxAk0zL6pJkXmTGVSQp {\n    display: none\n}\n  }\n", "",{"version":3,"sources":["NavBar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,mBAAmB;;EAEnB,eAAe;EACf,aAAa;EACb,WAAW;EACX,WAAW;;EAEX,iCAAiC;EACjC,+BAA+B;AAKjC;;EAHE,2CAdF;IAeI;AAEJ;EADE","file":"NavBar.css","sourcesContent":[".NavBar {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n  align-items: center;\n  \n  position: fixed;\n  height: 100vh;\n  width: 6rem;\n  z-index: 10;\n  \n  background-color: var(--charcoal);\n  transition: opacity .5s ease-in;\n\n  @media only screen and (max-width: 768px) {\n    display: none;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"NavBar": "lSNxAk0zL6pJkXmTGVSQp"
};
module.exports = exports;
