// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".oUyhr0thNtirMmuCkc3yS {\n  width: 100%;\n  display: flex;\n  margin: 5%;\n} \n  \n  .oUyhr0thNtirMmuCkc3yS dt._15bu8f7Fi5LYgnG7B2ty8a {\n    width: 40%;\n    color: var(--charcoal);\n  } \n  \n  .oUyhr0thNtirMmuCkc3yS div.DPQft6WrlzK39u1WaM3m_ {\n    display: flex;\n    flex-direction: column;\n    width: 40%;\n  } \n  \n  .oUyhr0thNtirMmuCkc3yS div.DPQft6WrlzK39u1WaM3m_ dd {\n      font-weight: 300;\n      -webkit-margin-before: 0;\n      -webkit-margin-after: 0;\n    } \n  \n  @media only screen and (min-width: 768px) {.oUyhr0thNtirMmuCkc3yS {\n    margin: 6%; \n    border-left: 4px solid var(--charcoal);\n    padding-left: 2rem\n}  \n  }\n", "",{"version":3,"sources":["TechItem.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,UAAU;AAuBZ;;EArBE;IACE,UAAU;IACV,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,UAAU;EAMZ;;EALE;MACE,gBAAgB;MAChB,wBAAwB;MACxB,uBAAuB;IACzB;;EAGF,2CArBF;IAsBI,UAAU;IACV,sCAAsC;IACtC;AAEJ;EADE","file":"TechItem.css","sourcesContent":[".TechItem {\n  width: 100%;\n  display: flex;\n  margin: 5%; \n  \n  dt.title {\n    width: 40%;\n    color: var(--charcoal);\n  }  \n\n  div.definitions {\n    display: flex;\n    flex-direction: column;\n    width: 40%;\n    dd {\n      font-weight: 300;\n      -webkit-margin-before: 0;\n      -webkit-margin-after: 0;\n    }\n  }\n\n  @media only screen and (min-width: 768px) {\n    margin: 6%; \n    border-left: 4px solid var(--charcoal);\n    padding-left: 2rem;  \n  }\n}\n"]}]);
// Exports
exports.locals = {
	"TechItem": "oUyhr0thNtirMmuCkc3yS",
	"title": "_15bu8f7Fi5LYgnG7B2ty8a",
	"definitions": "DPQft6WrlzK39u1WaM3m_"
};
module.exports = exports;
