// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._2KhwI9HWfT5LH2S5UHhfoo {\n  padding: 2rem 6rem;\n  border: 5px solid white;\n  border-radius: 2.5rem;\n  font-size: 1.8rem;\n  text-align: center;\n  text-decoration: none;\n  transition: color .1s ease-out, border .1s ease-out;\n}\n\n  ._2KhwI9HWfT5LH2S5UHhfoo:hover {\n    color: var(--charcoal);\n    border: 5px solid var(--charcoal);\n    cursor: pointer;\n  }\n\n  @media only screen and (max-width: 768px) {._2KhwI9HWfT5LH2S5UHhfoo {\n    font-size: 1.4rem;\n    padding: 1.6rem 4rem;\n    border: 4px solid white;\n    border-radius: 2rem\n}\n  }\n", "",{"version":3,"sources":["LargeButton.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,qBAAqB;EACrB,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;EACrB,mDAAmD;AAcrD;;EAZE;IACE,sBAAsB;IACtB,iCAAiC;IACjC,eAAe;EACjB;;EAEA,2CAfF;IAgBI,iBAAiB;IACjB,oBAAoB;IACpB,uBAAuB;IACvB;AAEJ;EADE","file":"LargeButton.css","sourcesContent":[".LargeButton {\n  padding: 2rem 6rem;\n  border: 5px solid white;\n  border-radius: 2.5rem;\n  font-size: 1.8rem;\n  text-align: center;\n  text-decoration: none;\n  transition: color .1s ease-out, border .1s ease-out;\n\n  &:hover {\n    color: var(--charcoal);\n    border: 5px solid var(--charcoal);\n    cursor: pointer;\n  }\n\n  @media only screen and (max-width: 768px) {\n    font-size: 1.4rem;\n    padding: 1.6rem 4rem;\n    border: 4px solid white;\n    border-radius: 2rem;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"LargeButton": "_2KhwI9HWfT5LH2S5UHhfoo"
};
module.exports = exports;
