// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._1Lt_gtik0xgaNOWUoLbqtt {\n  width: 34rem;\n  height: 21rem;\n  position: relative;\n  margin-top: 1rem;\n  margin-bottom: 2rem;\n}\n\n  @media only screen and (max-width: 768px) {._1Lt_gtik0xgaNOWUoLbqtt {\n    width: 18rem;\n    height: 11.4rem\n}\n  }\n", "",{"version":3,"sources":["Carousel.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AAMrB;;EAJE,2CAPF;IAQI,YAAY;IACZ;AAEJ;EADE","file":"Carousel.css","sourcesContent":[".Carousel {\n  width: 34rem;\n  height: 21rem;\n  position: relative;\n  margin-top: 1rem;\n  margin-bottom: 2rem;\n\n  @media only screen and (max-width: 768px) {\n    width: 18rem;\n    height: 11.4rem;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"Carousel": "_1Lt_gtik0xgaNOWUoLbqtt"
};
module.exports = exports;
