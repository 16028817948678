// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._29rOjHfa7n9H5H5ausBHiQ {\n  width: 3rem;\n  height: 3rem;\n  position: absolute;\n  top: 90%;\n  left: 50%;\n  cursor: pointer;\n  z-index: 1;\n}\n\n  @media only screen and (max-width: 768px) {._29rOjHfa7n9H5H5ausBHiQ {\n    left: 45%\n}\n  }\n", "",{"version":3,"sources":["Arrow.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,eAAe;EACf,UAAU;AAKZ;;EAHE,2CATF;IAUI;AAEJ;EADE","file":"Arrow.css","sourcesContent":[".Arrow {\n  width: 3rem;\n  height: 3rem;\n  position: absolute;\n  top: 90%;\n  left: 50%;\n  cursor: pointer;\n  z-index: 1;\n\n  @media only screen and (max-width: 768px) {\n    left: 45%;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"Arrow": "_29rOjHfa7n9H5H5ausBHiQ"
};
module.exports = exports;
