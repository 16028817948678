// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._52vtcY2nEYMndQDwzdwMA {\n  position: relative;\n  top: 7%;\n  right: 8%;\n  text-align: right;\n  margin: 2rem;\n}\n\n  @media only screen and (max-width: 768px) {._52vtcY2nEYMndQDwzdwMA {\n    text-align: center;\n    right: 0;\n    top: 3%;\n    margin: 1rem\n}\n  }\n", "",{"version":3,"sources":["PageHeading.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,OAAO;EACP,SAAS;EACT,iBAAiB;EACjB,YAAY;AAQd;;EANE,2CAPF;IAQI,kBAAkB;IAClB,QAAQ;IACR,OAAO;IACP;AAEJ;EADE","file":"PageHeading.css","sourcesContent":[".PageHeading {\n  position: relative;\n  top: 7%;\n  right: 8%;\n  text-align: right;\n  margin: 2rem;\n\n  @media only screen and (max-width: 768px) {\n    text-align: center;\n    right: 0;\n    top: 3%;\n    margin: 1rem;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"PageHeading": "_52vtcY2nEYMndQDwzdwMA"
};
module.exports = exports;
