// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._1IPhHTxxL2hrHCeEMIXg_R a {\n    text-decoration: none;\n    color: white;\n  }\n\n    ._1IPhHTxxL2hrHCeEMIXg_R a:hover {\n      color: var(--translucent-charcoal);\n    }\n", "",{"version":3,"sources":["ContributorsList.css"],"names":[],"mappings":"AACE;IACE,qBAAqB;IACrB,YAAY;EAKd;;IAHE;MACE,kCAAkC;IACpC","file":"ContributorsList.css","sourcesContent":[".ContributorsList {\n  a {\n    text-decoration: none;\n    color: white;\n\n    &:hover {\n      color: var(--translucent-charcoal);\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"ContributorsList": "_1IPhHTxxL2hrHCeEMIXg_R"
};
module.exports = exports;
