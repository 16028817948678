// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._16UUHLG6A2kbUeaOcMxDkb {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  height: 2.5rem;\n}\n\n._1Sl3E12JiMYiAzCiEEBsjx {\n  display: flex;\n  justify-content: center;\n  height: 2.5rem;\n}\n\n._349cqBh4Gbwxfbld27VZNF:hover {\n    color: var(--translucent-charcoal);\n  }\n", "",{"version":3,"sources":["GitHubLinks.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,8BAA8B;EAC9B,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,cAAc;AAChB;;AAGE;IACE,kCAAkC;EACpC","file":"GitHubLinks.css","sourcesContent":[".spaceBetween {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  height: 2.5rem;\n}\n\n.centered {\n  display: flex;\n  justify-content: center;\n  height: 2.5rem;\n}\n\n.link {\n  &:hover {\n    color: var(--translucent-charcoal);\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"spaceBetween": "_16UUHLG6A2kbUeaOcMxDkb",
	"centered": "_1Sl3E12JiMYiAzCiEEBsjx",
	"link": "_349cqBh4Gbwxfbld27VZNF"
};
module.exports = exports;
