// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".-mgBB1l4P8wRJDozze2k5 {\n  background-color: var(--taupe);\n  position: relative;\n  height: 100vh;\n  overflow: scroll;\n}\n", "",{"version":3,"sources":["AboutContainer.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,kBAAkB;EAClB,aAAa;EACb,gBAAgB;AAClB","file":"AboutContainer.css","sourcesContent":[".AboutContainer {\n  background-color: var(--taupe);\n  position: relative;\n  height: 100vh;\n  overflow: scroll;\n}\n"]}]);
// Exports
exports.locals = {
	"AboutContainer": "-mgBB1l4P8wRJDozze2k5"
};
module.exports = exports;
